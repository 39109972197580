import React from "react";

import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import CustomSnackBar from "../../Components/CustomSnackBar/CustomSnackBar";
import CustomTextInput from "../../Components/CustomTextInput/CustomTextInput";
import Loading from "../../Components/Loading/Loading";
import Logo from "../../Components/Logo/Logo";
import {
  Mutation,
  MutationCreateClientArgs,
} from "../../graphql/__generated__/types";
import { SIGNUP } from "../../graphql/signup/mutation";

interface Isignup {
  email: string;
  password: string;
  reasonSocial: string;
  postalCode: string;
  city: string;
  phone: string;
  agreement: boolean;
  newsLetter: boolean;
}

const defaultValues: Isignup = {
  agreement: false,
  city: "",
  email: "",
  newsLetter: false,
  password: "",
  phone: "",
  postalCode: "",
  reasonSocial: "",
};

const Signup: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Isignup>({ defaultValues });

  const [open, setOpen] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const [doSingUp, { loading }] = useMutation<
    Mutation,
    MutationCreateClientArgs
  >(SIGNUP, {
    onCompleted: (data) => {
      if (data.CreateClient?.id) {
        reset(defaultValues);
        setOpen(true);
      }
    },
  });

  const onConfirm = (data: Isignup) => {
    doSingUp({
      variables: {
        input: data,
      },
    });
  };
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: "10px",
      }}
    >
      <Loading loading={loading} />
      <CustomSnackBar
        open={open}
        setOpen={setOpen}
        message="Demande envoyé avec succès"
      />
      <div style={{ width: "20%" }}>
        <Logo />
      </div>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          marginTop: "20px",
        }}
      >
        <Typography>
          <Typography style={{ fontWeight: "bold" }}>Déjà client ?</Typography>
          Vos identifiants et mots de passe sont les mêmes que ceux de votre
          espace client web.
        </Typography>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomTextInput
              id="E-mail"
              label="E-mail"
              fullWidth={false}
              value={value}
              onChange={onChange}
              error={Boolean(errors.email?.message)}
              helperText={errors.email?.message}
            />
          )}
          name="email"
          rules={{
            required: {
              value: true,
              message: "Ce champ est requis.",
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Ce champ doit être un adresse e-mail valide",
            },
          }}
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomTextInput
              id="Mot de passe *"
              label="Mot de passe *"
              fullWidth={false}
              value={value}
              onChange={onChange}
              error={Boolean(errors.password?.message)}
              helperText={errors.password?.message}
            />
          )}
          name="password"
          rules={{
            required: "Ce champ est requis.",
            minLength: {
              value: 6,
              message: "Le champ doit contenir au moins 6 caractères.",
            },
          }}
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomTextInput
              id="Raison Sociale *"
              label="Raison Sociale *"
              fullWidth={false}
              onChange={onChange}
              value={value}
              error={Boolean(errors.reasonSocial?.message)}
              helperText={errors.reasonSocial?.message}
            />
          )}
          name="reasonSocial"
          rules={{ required: "Ce champ est requis." }}
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomTextInput
              label="Code postal *"
              error={Boolean(errors.postalCode?.message)}
              helperText={errors.postalCode?.message}
              value={value}
              onChange={onChange}
            />
          )}
          name="postalCode"
          rules={{ required: "Ce champ est requis." }}
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomTextInput
              label="Ville *"
              error={Boolean(errors.city?.message)}
              helperText={errors.city?.message}
              value={value}
              onChange={onChange}
              fullWidth={false}
            />
          )}
          name="city"
          rules={{ required: "Ce champ est requis." }}
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <CustomTextInput
              label="Téléphone *"
              error={Boolean(errors.phone?.message)}
              helperText={errors.phone?.message}
              value={value}
              onBlur={onBlur}
              fullWidth={false}
              onChange={onChange}
              type="number"
            />
          )}
          name="phone"
          rules={{
            required: { value: true, message: "Ce champ est requis." },
            minLength: {
              value: 10,
              message: "Le champ doit contenir au moins 10 caractères.",
            },
          }}
          defaultValue=""
        />

        <FormControlLabel
          value="Newsletter"
          control={<Checkbox />}
          label="Newsletter"
          labelPlacement="end"
        />
        <FormControlLabel
          value="Politique de confidentialité *"
          control={<Checkbox />}
          label="Politique de confidentialité *"
          labelPlacement="end"
        />
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ textTransform: "unset", width: "250px" }}
          onClick={handleSubmit(onConfirm)}
        >
          S'inscrire
        </Button>
        <Button
          variant="contained"
          color="inherit"
          size="large"
          style={{ textTransform: "unset", width: "250px", marginLeft: "10px" }}
          onClick={() => navigate(-1)}
        >
          Retour
        </Button>
      </div>
    </Box>
  );
};

export default Signup;
