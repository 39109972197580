import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "calc(100vh - 172px)",
    display: "flex",
    overflow: "scroll",
    background: "white",
    alignItems: "center",
    flexDirection: "column",
    borderTopLeftRadius: "21px",
    boxShadow: "0px -12px 6px #00000029",
  },
  containerCard: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    padding: "19px 0 19px 0",
  },
  iframeStyle: {
    display: "flex",
    flex: "1",
    width: "100%",
    height: "95vh",
  },
  btnClose: {
    backgroundColor: "#FFF",
    padding: 10,
    boxShadow:
      "0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
  },
  containerIframe: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
});
